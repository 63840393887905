import React from 'react';
import Link from 'next/link';
import BasePage from '@/components/BasePage';
import Booking from '@/components/Booking';
import {
  Row,
  Col,
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Translation Higher Order Component
import { translate, getLanguage } from 'react-switch-lang';

const Services = ({ t }) => {
  return (
    //React.Fragment is used instead of <div> or <> to hide it from source
    <React.Fragment>
      {/* Base Page - Shared component with Container */}
      <BasePage
        id='services-page'
        className='services-page pt-5'
        role='article'
      >
        <Container>
          <article>
            <Row>
              <Col className='text-center pt-5' md='12'>
                <h1 className='page_subtext text-uppercase'>
                  {t('services.important')}
                </h1>
              </Col>
            </Row>
            {getLanguage() == 'sv' ? (
              <Row className='pb-5'>
                <Col lg='6' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='shadowed-img'
                          top
                          width='100%'
                          src='/images/earlyScan1.webp'
                          alt={t('prices.early.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('prices.early.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>{t('prices.early.text')}</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='6' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='shadowed-img'
                          top
                          width='100%'
                          src='/images/genderScan.webp'
                          alt={t('prices.gender.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('services.gender_determination.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>
                        {t('services.gender_determination.text')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='6' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='shadowed-img'
                          top
                          width='100%'
                          src='/images/3d4.webp'
                          alt={t('prices.3d.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('prices.3d.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>{t('prices.3d.text')}</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='6' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='shadowed-img'
                          top
                          width='100%'
                          src='/images/earlyScan.webp'
                          alt={t('prices.hearth.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('prices.hearth.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>{t('prices.hearth.text')}</CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <Row className='pb-5'>
                <Col lg='3' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='services-images shadowed-img'
                          top
                          width='100%'
                          src='/images/3d4.webp'
                          alt={t('prices.3d.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('services.3d_scanning.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>{t('services.3d_scanning.text')}</CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg='3' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='services-images shadowed-img'
                          top
                          width='100%'
                          src='/images/earlyScan1.webp'
                          alt={t('prices.early.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('services.early_scanning.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>{t('services.early_scanning.text')}</CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='3' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='services-images shadowed-img'
                          top
                          width='100%'
                          src='/images/genderScan.webp'
                          alt={t('prices.gender.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('services.gender_determination.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>
                        {t('services.gender_determination.text')}
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='3' md='6' className='pb-5'>
                  <Card className='services-card shadowed-img'>
                    <Link passHref href='/prices'>
                      <a>
                        <CardImg
                          className='services-images shadowed-img'
                          top
                          width='100%'
                          src='/images/acupuncture.webp'
                          alt={t('prices.acupuncture.title')}
                        />
                      </a>
                    </Link>
                    <CardBody>
                      <Link passHref href='/prices'>
                        <a>
                          <CardTitle
                            className='page_subtext text-uppercase pt-3'
                            tag='h2'
                          >
                            {t('services.acupuncture.title')}
                          </CardTitle>
                        </a>
                      </Link>
                      <CardText>{t('services.acupuncture.text')}</CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            <Booking />
          </article>
        </Container>
      </BasePage>
    </React.Fragment>
  );
};

export default translate(Services);
