import React from 'react';
import BasePage from '@/components/BasePage';
import { Row, Col, Container, Card, CardText, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicNavLink from '@/Handlers/BasicNavLink';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';

const Info = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="info-page" className="info-page" role="article">
				<Container>
					<article className="pb-5">
						<Row>
							<Col className="text-center" md="12">
								<h3 className="page_text font-weight-bold">
									<FontAwesomeIcon icon={[ 'fas', 'address-card' ]} /> &nbsp;
									{`${t('navbar.pregnancy')} ${t('info.and')} ${t('navbar.prices')}`}
								</h3>
								<p className="page_subtext text-uppercase">{t('info.title')}</p>
							</Col>
							<Col className="pb-5">
								<Card className="shadowed-img">
									<CardBody className="text-center">
										<CardTitle className="page_subtext text-uppercase" tag="h4">
											{t('navbar.pregnancy')}
										</CardTitle>
										<CardText>
										{t('info.pregnancy')}
										</CardText>
										<BasicNavLink height="line-height booking_button" route="/pregnancy" title={t('navbar.pregnancy')} />
									</CardBody>
								</Card>
							</Col>
							<Col className="pb-5">
								<Card className="shadowed-img">
									<CardBody className="text-center">
										<CardTitle className="page_subtext text-uppercase" tag="h4">
											{t('navbar.prices')}
										</CardTitle>
										<CardText>{t('info.prices')}</CardText>
										<BasicNavLink height="line-height booking_button" route="/prices" title={t('navbar.prices')} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</article>
				</Container>
			</BasePage>
		</React.Fragment>
	);
};

export default translate(Info);
