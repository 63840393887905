import React from 'react';
import BaseLayout from '@/components/layouts/BaseLayout';
import Main from '@/pages/main';
import Services from '@/pages/services';
import Info from '@/pages/info';
// Translation Higher Order Component
import { translate } from 'react-switch-lang';
//Calls everything what is included in SuperComponent
const Index = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Layout - Shared component */}
			<BaseLayout
				className="cover"
				title={t('main.title')}
				description={`${t('about.text')} ${t('about.text2')}`}
				keywords={`${t('SEO.keywords_common')} ${t('SEO.keywords_main')}`}
				canonical="/"
			>
				<Main />
				<Services />
				<Info />
			</BaseLayout>
		</React.Fragment>
	);
};
export default translate(Index);
