import React, { useState } from 'react';
import BasePage from '@/components/BasePage';
import { Row, Carousel, CarouselItem, CarouselControl, CarouselIndicators } from 'reactstrap';

// Translation Higher Order Component
import { translate } from 'react-switch-lang';
const items = [
	{
		src: '/images/picture2',
		altText: 'pictures.main2',
	},
	{
		src: '/images/picture1',
		altText: 'pictures.main1',
	},
	{
		src: '/images/picture3',
		altText: 'pictures.main3',
	},
	{
		src: '/images/picture4',
		altText: 'pictures.main4',
	}
];

const Main = ({ t }) => {
	const [ activeIndex, setActiveIndex ] = useState(0);
	const [ animating, setAnimating ] = useState(false);

	const next = () => {
		if (animating) return;
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	};

	const previous = () => {
		if (animating) return;
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	};

	const goToIndex = (newIndex) => {
		if (animating) return;
		setActiveIndex(newIndex);
	};

	const slides = items.map((item) => {
		return (
			<CarouselItem
				className="carousel-item"
				onExiting={() => setAnimating(true)}
				onExited={() => setAnimating(false)}
				key={item.src}
			>
				<img
					width="100%"
					// height="1600"
					className="main-page_images"
					alt={t(item.altText)}
					src={`${item.src}.webp`}
				/>
			</CarouselItem>
		);
	});

	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="main-page" className="main-page">
				<Row>
					<Carousel className="w-100" activeIndex={activeIndex} next={next} previous={previous}>
						<CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
						{slides}
						<CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
						<CarouselControl direction="next" directionText="Next" onClickHandler={next} />
					</Carousel>
				</Row>
			</BasePage>
		</React.Fragment>
	);
};

export default translate(Main);
